@import "./_Styles/variables.scss";
@import "./_Styles/mixins.scss";
@import "~react-quill/dist/quill.snow.css";

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures full viewport height */
  justify-content: space-between;
  background-color: $primary-color;
  .container {
    @include container;
  }
  .blog {
    background-color: $primary-color;
    min-height: 100vh;
  }
}
