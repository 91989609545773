@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.analytics {
  .analytics-content {
    margin-bottom: 40px;
    h1 {
      @include h1;
    }
  }
}
