@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.top-nav {
  background-color: $primary-color;
  position: sticky;
  top: 0;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  z-index: 1001;
  &.scrolled {
    box-shadow: 0 1px 7px $base-font-color;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 150px;
        height: auto;
        @include sm {
          width: 200px;
        }
      }
    }

    // Hamburger/Cross Icon Styling
    .menu-icon {
      display: block;
      cursor: pointer;
      position: relative;
      width: 30px;
      height: 30px;
      z-index: 2000;

      // Hamburger icon lines
      .hamburger,
      .cross {
        width: 100%;
        height: 2px;
        background-color: $base-font-color;
        position: absolute;
        top: 50%;
        left: 0;
        transition: 0.3s;
      }

      // Hamburger (default)
      .hamburger {
        &:before,
        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $base-font-color;
        }
        &:before {
          top: -8px;
        }
        &:after {
          top: 8px;
        }
      }

      // Cross (when menu is open)
      .cross {
        transform: rotate(45deg);

        &:before {
          transform: rotate(-90deg);
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $base-font-color;
        }
        &:after {
          content: none;
        }
      }
    }

    // Menu list styling
    .nav-links {
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      //   background-color: white;
      z-index: 1000;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
      transform: translateY(-10px);
      transition: all 0.4s ease-in-out;
      background-color: $primary-color;

      // When menu is open (small screens)
      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      nav {
        background-color: $primary-color;
        font-weight: $large-font-weight;
        text-transform: uppercase;
        ul {
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          background-color: $primary-color;

          li {
            margin: 10px 0;

            a.contact {
              //   text-decoration: none;
              //   color: $secondary-color;
              //   font-size: $base-font-size;
              //   text-align: center;
              //   padding: 10px 0;
              //   display: block;
              //   width: 100%;
              //   @include transition-all;
              // @include hover-color-transition;

              &:hover {
                transform: scale(1.05);
                text-decoration: none;
              }
            }
            a {
              text-decoration: none;
              color: $secondary-color;
              font-size: $base-font-size;
              text-align: center;
              padding: 10px 0;
              padding-right: 10px;
              display: block;
              @include transition-all;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  // Larger screen (tablet and desktop)
  @include sm {
    .container {
      .menu-icon {
        display: none; // Hide hamburger menu icon on larger screens
      }

      .nav-links {
        display: flex; // Instead of block, ensure the menu is flex for desktop
        position: static;
        width: auto;
        opacity: 1;
        visibility: visible;
        transform: none;
        box-shadow: none;
        transition: none; // Remove transition for larger screens

        nav ul {
          flex-direction: row; // Horizontal layout for desktop
          align-items: center;
          padding: 0 20px;

          li {
            margin-left: 20px;
            margin-top: 0px;
            margin-bottom: 0px;

            a.contact {
              border: 5px solid $secondary-color;
              border-radius: 100px;
              font-size: $base-font-size + 2;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
