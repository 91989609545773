//Font-sizes
$base-font-size: 16px;
$h1-font-size: 32px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;

$small-font-weight: 300;
$base-font-weight: 500;
$large-font-weight: 700;
$xl-font-weight: 900;

$base-font-color: #333;
$primary-color: #f8d700;
$secondary-color: #133f66;

//Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
