//Breakpoints
@mixin sm {
  @media only screen and (min-width: $sm) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: $md) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: $xl) {
    @content;
  }
}

//Animation
@mixin transition-all {
  transition: all 0.3s;
}

//Container
@mixin container {
  padding: 20px;
}

//Titles
@mixin title {
  padding-bottom: 20px;
  text-transform: uppercase;
}

@mixin h1 {
  @include title;
  font-size: $h1-font-size;
  font-weight: $large-font-weight;
}

@mixin h2 {
  font-size: $h2-font-size;
  font-weight: $large-font-weight;
}

@mixin h3 {
  font-size: $h3-font-size;
  font-weight: $small-font-weight;
}

//Button
@mixin primary-btn {
  padding: 10px 20px;
  border: 4px solid $secondary-color;
  border-radius: 5px;
  color: $secondary-color;
  background-color: $primary-color;
  @include transition-all;
  &:hover {
    background-color: $secondary-color;
    color: $primary-color;
  }
}

@mixin secondary-btn {
  padding: 10px 20px;
  border: 4px solid $primary-color;
  border-radius: 5px;
  color: $primary-color;
  background-color: $secondary-color;
  @include transition-all;
  &:hover {
    background-color: $primary-color;
    color: $secondary-color;
  }
}
