.editor-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
}

.toolbar {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;

  button {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #ddd;
    }
  }
}

.editor {
  width: 100%;
  min-height: 400px;
  height: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  overflow: auto;
  outline: none;
  font-size: 16px;
  line-height: 1.6;

  img,
  iframe {
    max-width: 100%;
    cursor: move;
    outline: 1px dashed #333;
  }
}

.video-wrapper {
  position: relative;
  display: inline-block;
  width: 560px;
  height: 315px;

  iframe {
    width: 100%;
    height: 100%;
  }

  .resizer {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #000;
    right: 0;
    bottom: 0;
    z-index: 1000;
    cursor: se-resize;
  }
}

/* Video Modal Styles */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.video-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;

  h3 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 8px 12px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s;

    &:first-of-type {
      background-color: #4caf50;
      color: white;

      &:hover {
        background-color: #45a049;
      }
    }

    &:last-of-type {
      background-color: #f44336;
      color: white;

      &:hover {
        background-color: #d32f2f;
      }
    }
  }
}
