@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.footer {
  text-align: center;
  background-color: white;
  .designs-by {
    font-size: $h3-font-size;
    font-weight: $base-font-weight;
    color: $secondary-color;
    margin: 0;
    padding-bottom: 10px;
  }
  .logos {
    // margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @include sm {
      flex-direction: row;
    }
    .logo {
      padding: 5px 20px;
      width: 150px;
      height: 60px;
      @include sm {
        padding: 0 20px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
