@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.blog {
  .container {
    margin: 0 auto;
    padding: 20px;
  }

  h1 {
    text-align: center;
    color: $secondary-color;
  }

  .blog-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    a {
      display: flex;
      text-decoration: none;
    }
  }

  .blog-item {
    background-color: $primary-color;
    color: $secondary-color;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 30px;
    height: auto;
    width: 250px;
    flex: 1 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include md {
      width: 300px;
    }

    @include lg {
      width: 350px;
    }

    &:hover {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
      transform: translateY(-5px);
      transition: all 0.3s ease;
    }

    h2 {
      margin: 0;
      padding-bottom: 20px;
      width: 100%;
    }

    img {
      max-width: 100%;
      max-height: 220px;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
}
