@import "../../_Styles/mixins.scss";
@import "../../_Styles/variables.scss";

.home {
  width: 100%;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  .section {
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    scroll-snap-align: start;

    &:nth-child(odd) {
      background-color: $primary-color;
      color: $secondary-color;
      text-align: left;
      justify-content: left;
    }

    &:nth-child(even) {
      background-color: $secondary-color;
      color: $primary-color;
      text-align: right;
      justify-content: right;
    }
    h1 {
      font-size: 40px;
      @include sm {
        font-size: 60px;
      }
      @include md {
        font-size: 80px;
      }
      @include lg {
        font-size: 80px;
      }
      @include lg {
        font-size: 120px;
      }
      span.oo {
        display: inline-block;
        animation: wiggle 3.5s infinite;
        letter-spacing: -8px;
        font-weight: 600;
        @include sm {
          letter-spacing: -12px;
        }
        @include md {
          letter-spacing: -16px;
        }
        @include lg {
          letter-spacing: -8px;
        }
        @include lg {
          letter-spacing: -25px;
        }
      }
      span.after-oo {
        letter-spacing: -3px;
        @include sm {
          letter-spacing: -5px;
        }
        @include md {
          letter-spacing: -5px;
        }
        @include lg {
          letter-spacing: -8px;
        }
        @include lg {
          letter-spacing: -8px;
        }
      }
    }
    .text-under-heading {
      width: 100%;
      @include h2;
      font-size: 18px;
      @include sm {
        font-size: 20px;
      }
      @include md {
        font-size: 24px;
      }
      @include lg {
        font-size: $h1-font-size;
      }
      @include lg {
        font-size: $h1-font-size;
      }
    }
    h2 {
      @include h2;
      font-size: 18px;
      @include sm {
        font-size: 24px;
      }
      @include md {
        font-size: 38px;
      }
      @include lg {
        font-size: 42px;
      }
      @include xl {
        font-size: 50px;
      }
    }
  }
  .section.section1 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: -30px;
    @include md {
      margin-top: auto;
    }
  }
  .section.section2,
  .section.section3,
  .section.section4 {
    flex-direction: column;
    justify-content: center;
    // align-items: flex-end;
    .section-block {
      width: 100%;
      padding: 20px;
      @include md {
        width: 50%;
      }
      &.padlock-block {
        margin-top: 60px;
        @include md {
          margin-top: 0px;
        }
      }
    }
    @include md {
      flex-direction: row;
    }
  }
  .section-bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    scroll-snap-align: start;
    color: $primary-color;
    background-color: $secondary-color;
    h2 {
      @include md {
        font-size: 50px;
      }
    }
    .text-under-heading {
      font-size: $base-font-size - 3;
      @include md {
        font-size: 22px;
      }
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        font-weight: $base-font-weight;
        padding: 5px;
        font-size: $base-font-size - 3;
        @include md {
          padding: 10px;
          font-size: $base-font-size;
        }
      }
    }
    .contact-link {
      color: $primary-color;
      font-weight: $base-font-weight;
    }
    .contact-btn {
      margin-top: 20px;
      border: 5px solid $primary-color;
      border-radius: 100px;
      font-size: $base-font-size + 2;
      padding: 10px;
      text-decoration: none;
      color: $secondary-color;
      font-weight: $large-font-weight;
      text-transform: uppercase;
      text-align: center;
      background-color: $primary-color;
      // padding: 10px 0;
      // padding-right: 10px;
      display: block;
      @include transition-all;
      &:hover {
        transform: scale(1.05);
        text-decoration: none;
        color: $primary-color;
        background-color: $secondary-color;
      }
    }
  }
  //   .section.section3,
  //   .section.section4 {
  //     flex-direction: column;
  //     justify-content: center;
  //   }
  /* Container for the wave arcs */
  .wave-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    // width: 800px;
    height: 240px;
    margin-bottom: -100px;
    @include md {
      margin-bottom: auto;
    }
  }

  .arc {
    position: absolute;
    border-radius: 50%;
    border: 20px solid $secondary-color;
    border-color: $secondary-color transparent transparent transparent;
    opacity: 0;
    // transform: scale(0);
  }

  .arc1 {
    width: 20px;
    height: 20px;
    @include md {
      width: 80px;
      height: 80px;
    }
  }

  .arc2 {
    width: 80px;
    height: 80px;
    @include md {
      width: 160px;
      height: 160px;
    }
  }

  .arc3 {
    width: 140px;
    height: 140px;
    @include md {
      width: 240px;
      height: 240px;
    }
  }

  .line {
    width: 300px;
    height: 10px;
    background-color: $secondary-color;
    margin-top: 30px;
    padding-top: 10px;
    @include md {
      width: 400px;
    }
  }

  @keyframes showWaveArc {
    0%,
    100% {
      opacity: 0;
      //   transform: scale(0);
    }
    50% {
      opacity: 1;
      // transform: scale(1);
    }
  }

  .animate-wave {
    animation: showWaveArc 2s ease-in-out infinite;
  }

  /* Container for the padlock */
  .padlock {
    position: relative;
    width: 150px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: auto;
  }

  /* The shackle of the padlock */
  .padlock-shackle {
    width: 110px;
    height: 100px;
    border: 14px solid $primary-color;
    border-bottom: none;
    border-radius: 80px 80px 0 0;
    position: absolute;
    top: -100px;
    transition: top 1s ease-in-out;
    z-index: 1;
    &.cover {
      border-right: none;
      border-top: none;
      top: 10px;
      left: 6px;
      //   left: -40px;
      border-radius: 0;
    }
  }

  .padlock-shackle.animate-shackle {
    top: -60px;
    &.cover {
      top: 10px;
    }
  }

  /* The body of the padlock */
  .padlock-body {
    width: 150px;
    height: 50%;
    background-color: $primary-color;
    // border-radius: 8px;
    position: relative;
    z-index: 2;
    // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    margin-top: 50px; /* Shift it below the shackle */
    @include md {
      width: 150px;
      height: 100%;
    }
  }

  /* Keyhole on the padlock */
  .padlock-keyhole {
    width: 15px;
    height: 30px;
    background-color: $secondary-color;
    border-radius: 10px;
    position: absolute;
    bottom: 75px;
    z-index: 3;
    @include md {
      bottom: 25px;
    }
  }

  /* Optional: shadow under the padlock for a 3D effect */
  //   .padlock-body::after {
  //     content: "";
  //     position: absolute;
  //     bottom: -10px;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     width: 100px;
  //     height: 10px;
  //     background-color: rgba(0, 0, 0, 0.2);
  //     border-radius: 50%;
  //   }
  /* General styling for the container */
  .graph-container {
    display: flex;
    margin: auto;
    width: 200px;
    height: 200px;
    border-left: 15px solid $secondary-color;
    border-bottom: 15px solid $secondary-color;
    position: relative;
    @include md {
      width: 200px;
      height: 200px;
    }
    @include lg {
      width: 250px;
      height: 250px;
    }
  }

  /* Graph container styling */
  .graph {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  /* Arrow container */
  .arrow {
    position: relative;
    animation: arrow-bounce 1s infinite;
  }

  /* Arrow base (rectangle) styling */
  .arrow-base {
    width: 30px;
    height: 50px;
    background-color: $secondary-color;
    margin: 0 auto;
    position: relative;
    top: -50px;
    @include md {
      top: -50px;
      width: 30px;
      height: 50px;
    }
    @include lg {
      top: -75px;
    }
  }

  /* Arrow head (triangle) styling */
  .arrow-head {
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 60px solid $secondary-color;
    position: relative;
    top: -50px;
    @include md {
      top: -50px;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 60px solid $secondary-color;
    }
    @include lg {
      top: -75px;
    }
  }

  /* Arrow bounce animation */
  @keyframes arrow-bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
