@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.contact {
  background-color: $primary-color;
  .iframe-container iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 375px; /* Default width */
    height: 1400px;
    @include lg {
      width: 540px;
      height: 1250px;
    }
  }
}
