@import "../../_Styles/variables.scss";
@import "../../_Styles/mixins.scss";

.performance-section {
  padding: 2rem;
  .performance-bar {
    // display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    @include md {
      margin-bottom: 1.5rem;
    }

    &.bottom {
      margin-top: 80px;
      flex-direction: column;
      .progress-bar {
        height: 40px;
        flex: auto;
        width: 100%;
        @include sm {
          height: 80px;
        }
      }
    }

    p {
      padding-bottom: 5px;
      flex: 1;
      font-size: 12px;
      margin: 0;
      @include xl {
        font-size: 30px;
      }
    }

    .progress-bar {
      flex: 2;
      height: 12px;
      background-color: #fff;
      border: 2px solid $secondary-color;
      margin-left: 1rem;
      position: relative;
      overflow: hidden;
      @include md {
        height: 25px;
      }

      .progress-bar-fill {
        height: 100%;
        background-color: $primary-color;
        width: 0; /* Start at 0% width */
        transition: width 1.5s ease-out; /* Smooth animation when width changes */
      }
    }
  }
}
